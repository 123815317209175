<template>
  <div :key="cont">
    <!-- Botões para criar condições -->
    <b-row class="demo-inline-spacing" style="margin-left: 0.3rem">
      <b-button
        v-b-modal.create_event
        variant="outline-success"
        @click="restart_values()"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Nova condição</span>
      </b-button>
      <b-button
        @click="format_devices"
        v-b-modal.create_grid
        variant="outline-success"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Nova cerca eletrônica</span>
      </b-button>
    </b-row>

    <!-- Card das condições -->
    <b-overlay opacity="0.5" :show="conditions_overlay" spinner-variant="success" spinner-type="grow" spinner-small
      rounded="sm">
    <b-card-group
      columns
      style="margin-top: 1rem"
      v-if="possible_conditions.length > 0"
    >
      <b-link
        v-for="(item, index) in possible_conditions"
        :key="'card_' + index + cont"
        @click="$store.state.selected_condition = item.id"
        to="/events"
        style="color: #6a6a6a"
      >
        <b-card class="condition_card">
          <b-row id="header">
            <b-media-aside class="ml-1">
              <b-avatar
                v-if="
                  item.rules.split('sinal: ', 2)[1].charAt(0) != 6 &&
                    item.rules.split('sinal: ', 2)[1].charAt(0) != 7
                "
                rounded
                variant="light-info"
                size="34"
                v-b-tooltip.hover.v-info
                title="Condição"
              >
                <feather-icon icon="ShuffleIcon" size="20" />
              </b-avatar>
              <b-avatar
                v-else
                rounded
                variant="light-warning"
                size="34"
                v-b-tooltip.hover.v-warning
                title="Cerca eletrônica"
              >
                <feather-icon icon="TriangleIcon" size="20" />
              </b-avatar>
            </b-media-aside>
            <div style="flex: auto; text-align: end; margin-right: 1rem">
              <b-navbar-nav class="nav align-items-end ml-auto">
                <b-nav-item-dropdown
                  style="max-width: 2rem; margin-right: 0.5rem"
                >
                  <template #button-content>
                    <b-button
                      style="padding: 0px; margin-right: 1rem; padding: 0.5em"
                      variant="flat-secondary"
                      class="btn-icon"
                    >
                      <feather-icon icon="MoreHorizontalIcon" />
                    </b-button>
                  </template>

                  <b-dropdown-item
                    @click="update_values(item)"
                    v-b-modal.edit_condition
                  >
                    <feather-icon size="16" icon="EditIcon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon size="16" icon="Trash2Icon" class="mr-50" />
                    <span>Apagar</span>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </div>
          </b-row>
          <h3 style="margin-top: 1rem; font-weight: 600">
            {{ item.rules.split('title: "')[1].split('"', 1)[0] }}
          </h3>
          <p style="font-size: 0.9rem">
            <span> Se </span>
            <span
              style="font-weight: bold"
              v-if="item.rules.split('variableId:', 2)[1].charAt(1) == 1"
            >
              a localização
            </span>
            <span v-else>
              {{
                get_variables.find(
                  (data) =>
                    data.id == item.rules.split("variableId:", 2)[1].charAt(1)
                ).name
              }}
            </span>
            <span> for </span>
            <span style="font-weight: bold">
              {{
                possible_signals.find(
                  (data) =>
                    data.id == item.rules.split("sinal:", 2)[1].charAt(1)
                ).name
              }}
              <span v-if="item.rules.split('variableId:', 2)[1].charAt(1) != 1">
                {{ item.rules.split(",", 1)[0].split(": ", 2)[1] }}
              </span>
            </span>
          </p>
        </b-card>
      </b-link>
    </b-card-group>
    <h3 v-else style="margin-top: 1rem">Nenhuma condição</h3>
    </b-overlay>

    <b-modal
      ref="modal-delete"
      id="modal-delete"
      centered
      title="Deletar grupo "
      hide-footer
    >
      <b-card-text>
        <div style="text-align: center">
          <feather-icon
            style="color: rgb(249, 175, 0)"
            icon="AlertCircleIcon"
            size="100"
          />
          <!-- {{this.delete_condition.rules.split('title:', 2)[1].split(',',1)[0]}} -->
          <h3 style="margin-top: 0.5rem">Deletar?</h3>
          <p>Esta ação não pode ser desfeita</p>
          <div style="margin-top: 2rem" @click="hideModal('modal-delete')">
            <b-button
              style="margin-right: 1.5rem"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              @click="confirm_delete(delete_condition)"
            >
              Delete isso!
            </b-button>

            <b-button variant="outline-danger"> Cancelar </b-button>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <!-- Modal criar cerca eletrôncia -->
    <b-modal
      ref="create_grid"
      title="Criar cerca eletrônica"
      id="create_grid"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="6">
          <b-form-group label="Grupo" label-for="group">
            <v-select
              id="group"
              label="name"
              v-model="$store.state.active_group"
              disabled
              :options="get_group"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Título" label-for="fence_title">
            <b-form-input
              v-model="fence_title"
              id="fence_title"
              placeholder="Nome para identificação"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Dispositivos" label-for="devices">
            <v-select
              multiple
              v-model="selected_devices"
              id="devices"
              label="name"
              :options="possible_devices"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Condição" label-for="condition">
            <v-select
              v-model="selected_condition"
              id="condition"
              label="name"
              :options="map_signals"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Mensagem de saída" label-for="exit_text">
            <b-form-input
              v-model="exit_text"
              id="exit_text"
              placeholder="Mensagem mostrada ao ocorrer o evento"
            />
          </b-form-group>          
        </b-col>
        <b-col md="6">
          <b-form-group label="Variável" label-for="variable">
            <v-select
              id="variable"
              label="name"
              v-model="event_variable"
              :options="get_variables"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Valor da variável" label-for="variable_value">
            <b-form-input
              v-model="variable_value"
              id="variable_value"
              placeholder="Valor vinculado à variável"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" style="align-self: center;">
          <b-form-checkbox
            checked="true"
            name="check-button"
            class="custom-control-success"
            v-model="confirm_view"
            switch
            inline
          >
            Confirmação de leitura
          </b-form-checkbox>
        </b-col>
        <div style="height: 35rem; width: 100%; margin-top: 1rem">
          <Mapbox :request="formated_devices" />
        </div>
      </b-row>

      <div
        style="margin-top: 3rem; text-align: end"
      >
        <b-button style="margin-right: 1.5rem" variant="danger" @click="hideModal('create_grid')">
          Cancelar
        </b-button>
        <b-button @click="create_grid()" variant="success"> Criar </b-button>
      </div>
    </b-modal>

    <!-- Modal criar alerta -->
    <b-modal
      ref="create_event"
      title="Criar alerta"
      id="create_event"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="6">
          <b-form-group label="Grupo" label-for="group">
            <v-select
              id="group"
              label="name"
              v-model="$store.state.active_group"
              disabled
              :options="get_group"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Dispositivos" label-for="devices">
            <v-select
              multiple
              v-model="selected_devices"
              id="devices"
              label="name"
              :options="possible_devices"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-badge
        v-if="rules.length == 0"
        @click="add_rule()"
        href="#"
        variant="success"
        style="margin-bottom: 1rem"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Adicionar regra</span>
      </b-badge>

      <b-col md="12" v-for="(item, index) in rules" :key="index">
        <b-card-actions title="Condição" action-collapse>
          <b-row>
            <b-col md="6">
              <b-form-group label="Títutlo" label-for="title">
                <b-form-input
                  v-model="rules[index].title"
                  id="title"
                  placeholder="Identificação da condição"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Variável" label-for="variables">
                <v-select
                  multiple
                  v-model="rules[index].variables"
                  id="variables"
                  label="name"
                  :options="get_variables"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Operação" label-for="devices">
                <v-select
                  v-model="rules[index].signal"
                  id="devices"
                  label="name"
                  :options="possible_signals"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="SetPoint" label-for="setpoint">
                <b-form-input
                  v-model="rules[index].setpoint"
                  id="setpoint"
                  placeholder="Ex: 56.5"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

      <b-row>
        <b-col md="6"> </b-col>

        <b-col md="6">
          <b-form-group label="Texto de saída" label-for="end">
            <b-form-input
              v-model="exit_text"
              id="end"
              placeholder="Deixe em branco para mensagem padrão"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6"> </b-col>

        <b-col md="6">
          <b-form-checkbox
            checked="true"
            name="check-button"
            class="custom-control-success"
            v-model="confirm_view"
            switch
            inline
          >
            Confirmação de leitura
          </b-form-checkbox>
        </b-col>
      </b-row>

      <div
        style="margin-top: 2rem; text-align: end"
        @click="hideModal('create_event')"
      >
        <b-button style="margin-right: 1.5rem" variant="danger">
          Cancelar
        </b-button>
        <b-button @click="create_condition()" variant="success">
          Criar
        </b-button>
      </div>
    </b-modal>

    <!-- Modal para editar condição -->
    <b-modal
      ref="edit_condition"
      title="Editar condição"
      id="edit_condition"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="6">
          <b-form-group label="Título" label-for="title">
            <b-form-input
              v-model="title"
              id="title"
              placeholder="Título da condição"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Sinal" label-for="signal">
            <v-select
              id="signal"
              label="name"
              v-model="signal"
              :options="possible_signals"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" v-if="signal.id != 7 && signal.id != 6">
          <b-form-group label="Variável" label-for="variable">
            <v-select
              id="variable"
              label="name"
              v-model="variable"
              :options="get_variables"
            />
          </b-form-group>
        </b-col>     
        <b-col md="6">
          <b-form-group label="Texto de saída" label-for="exit_text">
            <b-form-input
              v-model="exit_text"
              id="exit_text"
              placeholder="Texto de saída"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="signal.id == 7 || signal.id == 6">
          <b-form-group label="Variável" label-for="variable">
            <v-select
              id="variable"
              label="name"
              v-model="event_variable"
              :options="get_variables"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="signal.id == 7 || signal.id == 6">
          <b-form-group label="Valor da variável" label-for="variable_value">
            <b-form-input
              v-model="variable_value"
              id="variable_value"
              placeholder="Valor vinculado à variável"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="signal.id != 7 && signal.id != 6">
          <b-form-group
            label="Setpoint"
            label-for="setpoint"
          >
            <b-form-input
              v-model="setpoint"
              id="setpoint"
              placeholder="Valor de comparação"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-checkbox
            style="margin-top: 1rem"
            checked="true"
            name="check-button"
            class="custom-control-success"
            v-model="confirm_view"
            switch
            inline
          >
            Confirmação de leitura
          </b-form-checkbox>
        </b-col>
      </b-row>

      <div
        style="height: 35rem; width: 100%; margin-top: 1rem"
        v-if="signal.id == 7 || signal.id == 6"
      >
        <Mapbox :request_fence="fence" :request="formated_devices" />
      </div>

      <div
        style="margin-top: 2rem; text-align: end"
        @click="hideModal('edit_condition')"
      >
        <b-button style="margin-right: 1.5rem" variant="danger">
          Cancelar
        </b-button>
        <b-button @click="patch_condition()" variant="success">
          Atualizar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from '@/service/api'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BOverlay,
  BTable,
  BCardText,
  BMediaAside,
  BMedia,
  BMediaBody,
  BNavbarNav,
  BNavItemDropdown,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BFormGroup,
  BCardGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BModal,
  VBModal,
  BCol,
  BRow,
  BFormCheckbox,
  BLink,
  BCardTitle,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cookies from "js-cookie";
import store from "@/store/index";
import axios from "axios";
import vSelect from "vue-select";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Mapbox from "../layouts/components/MapConditions.vue";
import eventBus from "@/store/eventBus";

export default {
  components: {
    BDropdown,
    BOverlay,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    VBTooltip,
    BCardTitle,
    BCardActions,
    BCardText,
    BFormCheckbox,
    BCardGroup,
    BRow,
    BCol,
    BCard,
    vSelect,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ToastificationContent,
    BModal,
    Mapbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }

    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }

    eventBus.$on("fencesData", (data) => {
      this.fences_points = data;
    });

    this.initial_requests();
  },

  data() {
    return {
      event_variable: [],
      variable_value: null,
      id: null,
      conditions_overlay: true,
      fence_title: "",
      fences_points: "",
      fence: "",
      cont: 0,
      formated_devices: "",
      setpoint: 0,
      message: "",
      title: "",
      variable: "",
      signal: "",
      exit_text: "",
      delete_condition: [],
      possible_conditions: [],
      selected_condition: [],
      possible_signals: [
        {
          id: 0,
          name: "maior que",
        },
        {
          id: 1,
          name: "maior ou igual que",
        },
        {
          id: 2,
          name: "menor que",
        },
        {
          id: 3,
          name: "menor ou igual que",
        },
        {
          id: 4,
          name: "igual à",
        },
        {
          id: 5,
          name: "diferente de",
        },
        {
          id: 6,
          name: "dentro da área",
        },
        {
          id: 7,
          name: "fora da área",
        },
      ],
      map_signals: [
        {
          id: 6,
          name: "Dentro da área",
        },
        {
          id: 7,
          name: "Fora da área",
        },
      ],
      confirm_view: false,
      exit_text: "",
      selected_devices: [],
      rule_cont: 0,
      items: [{ ACTION: "Collapse", ICON: "ChevronDownIcon" }],
      possible_devices: [],
      get_variables: [],
      get_group: [],
      rules: [],
    };
  },
  methods: {
    // Deletar condição
    async confirm_delete(item) {
      var self = this;

      var params = "id=" + item.id + "&groupId= " + item.groupId;
      await axios
        .delete("https://tekie.cloud:3031/condicoes?" + params, {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })

        .then(function(response) {
          self.initial_requests();
          let title = "Notificação";
          let icon = "BellIcon";
          let text = "Condição deletada!";
          let variant = "success";
          self.$toast({
            component: ToastificationContent,
            props: {
              title,
              icon,
              text,
              variant,
            },
          });
        })

        .catch(function(e) {
          //"Erro ao tentar deletar uma condição: ", e);
        });
      this.initial_requests();
      // top.location.href = "/conditions";
    },

    // Criar string para card selecionar dispositivos
    format_devices() {
      this.formated_devices = "";
      for (let i = 0; i < this.possible_devices.length; i++) {
        this.formated_devices += "&id=" + this.possible_devices[i].id;
      }
    },

    // Atualizar valores para atualizar cerca eletrônica
    update_values(item) {
      this.id = item.id;
      this.variable_value = item.valueVariable;
      this.event_variable = this.get_variables.find(e => e.id == item.variableId);
      this.title = item.rules.split('title: "')[1].split('"', 1)[0];
      this.variable = this.get_variables.find(
        (data) => data.id == item.rules.split("variableId:", 2)[1].charAt(1)
      );
      this.signal = this.possible_signals.find(
        (data) => data.id == item.rules.split("sinal:", 2)[1].charAt(1)
      );
      this.exit_text = item.message;
      this.confirm_view = false;
      this.setpoint = item.rules.split(",", 1)[0].split(": ", 2)[1];
      if (item.confirmView == 1) {
        this.confirm_view = true;
      }
      if (this.signal.id == 7 || this.signal.id == 6) {
        this.fence = item.rules.split("grid: [", 10)[1].slice(0, -2);
        this.fence = this.fence.slice(1, -1);
        this.format_devices();
      }
    },

    // Atualizar condição
    async patch_condition() {
      if (this.signal.id == 7 || this.signal.id == 6) {
        eventBus.$emit("getFenceData");
        var data = {
          variableId: this.event_variable.id,
          valueVariable: this.variable_value,
          id: this.id,
          message: this.exit_text,
          confirmView: this.confirm_view,
          groupId: store.state.active_group.id,
          rules:
            "{setpoint: " +
            this.setpoint +
            ',title: "' +
            this.title +
            '", variableId: ' +
            this.variable.id +
            ", sinal: " +
            this.signal.id +
            ", grid: " +
            this.fences_points +
            "}",
        };
      } else {
        var data = {
          id: this.id,
          message: this.exit_text,
          confirmView: this.confirm_view,
          groupId: store.state.active_group.id,
          rules:
            "{setpoint: " +
            this.setpoint +
            ',title: "' +
            this.title +
            '", variableId: ' +
            this.variable.id +
            ", sinal: " +
            this.signal.id +
            "}",
        };
      }
      await api.patch_condition(data);
      
      this.conditions_overlay = true;
      this.initial_requests();
      this.cont += 1;
    },

    // Criar alerta
    async create_condition() {
      let condition = "";
      let selected_devices_formatted = "";
      for (let i = 0; i < this.selected_devices.length; i++) {
        if (this.selected_devices.length == i + 1) {
          selected_devices_formatted += this.selected_devices[i].id;
        } else {
          selected_devices_formatted += this.selected_devices[i].id + ",";
        }
      }
      let data = {
        groupId: store.state.active_group.id,
        message: this.exit_text,
        confirm_view: this.confirm_view,
        rules:
          "{setpoint: " +
          this.rules[0].setpoint +
          ", title: " +
          '"' +
          this.rules[0].title +
          '"' +
          ", variableId: " +
          this.rules[0].variables[0].id +
          ", sinal: " +
          this.rules[0].signal.id +
          "}",
      };

      const headers = {
        Authorization: this.$store.state.token_api,
      };

      await axios
        .post("https://tekie.cloud:3031/condicoes", data, { headers })

        .then(function(response) {
          condition = response.data.id;
        });

      if (selected_devices_formatted == "-1") {
        for (let i = 0; i < this.possible_devices.length; i++) {
          data = {
            deviceId: this.possible_devices[i].id,
            condicoId: condition,
            groupId: store.state.active_group.id,
          };

          const headers = {
            Authorization: this.$store.state.token_api,
          };

          await axios
            .post("https://tekie.cloud:3031/condicoes-devices", data, {
              headers,
            })

            .then(function(response) {});
        }
      } else {
        let devices_list = selected_devices_formatted.split(",", 100);

        for (let i = 0; i < devices_list.length; i++) {
          data = {
            deviceId: devices_list[i],
            condicoId: condition,
            groupId: store.state.active_group.id,
          };

          const headers = {
            Authorization: this.$store.state.token_api,
          };

          await axios
            .post("https://tekie.cloud:3031/condicoes-devices", data, {
              headers,
            })

            .then(function(response) {});
        }
      }
      this.initial_requests();
    },

    // Esconder modais
    hideModal(value) {
      this.$refs[value].hide();
    },

    // Resetar valores para criar alerta
    restart_values() {
      this.rule_cont = 0;
      this.rules = [];
    },

    // Criar card para regra: Em criar alerta
    add_rule() {
      this.rule_cont++;
      this.rules.push({
        title: "",
        variables: "",
        signal: "",
        setpoint: "",
      });
    },

    // Requisições iniciais
    async initial_requests() {
      var self = this;

      let devices = await api.get_devices();
      self.possible_devices = devices.filter(e => e.groupId == store.state.active_group.id)
      self.get_variables = await api.get_variables();
      self.get_group = await api.get_groups();

      try{
        self.possible_conditions = await api.get_conditions();
      } catch {
        self.possible_conditions = [];
      }

      self.conditions_overlay = false;
      self.cont += 1
    },
    
    // Criar cerca eletrônica
    async create_grid() {
      var self = this;
      let condition = "";
      let selected_devices_formatted = "";
      for (let i = 0; i < this.selected_devices.length; i++) {
        if (this.selected_devices.length == i + 1) {
          selected_devices_formatted += this.selected_devices[i].id;
        } else {
          selected_devices_formatted += this.selected_devices[i].id + ",";
        }
      }
      let data = {
        groupId: store.state.active_group.id,
        message: this.exit_text,
        confirm_view: this.confirm_view,
        valueVariable: this.variable_value,
        variableId: this.event_variable.id,
        rules:
          '{setpoint: 1, title: "' +
          this.fence_title +
          '", variableId: 1, sinal: ' +
          this.selected_condition.id +
          ", grid: " +
          this.fences_points +
          "}",
      };
      if (
        this.fence_title == "" ||
        this.selected_condition.id == "" ||
        this.fences_points == ""
      ) {
        let title = "Notificação";
        let icon = "BellIcon";
        let text = "Todos os campos são necessários";
        let variant = "danger";
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        });
      } else {
        self.hideModal('create_grid');  
        const headers = {
          Authorization: this.$store.state.token_api,
        };

        await axios
          .post("https://tekie.cloud:3031/condicoes", data, { headers })

          .then(function(response) {
            condition = response.data.id;
          })
          .catch(function(e) {
            //" Erro ao tentar criar condição: ",e);
          });

        if (selected_devices_formatted == "-1") {
          for (let i = 0; i < this.possible_devices.length; i++) {
            data = {
              deviceId: this.possible_devices[i].id,
              condicoId: condition,
              groupId: store.state.active_group.id,
            };

            const headers = {
              Authorization: this.$store.state.token_api,
            };

            await axios
              .post("https://tekie.cloud:3031/condicoes-devices", data, {
                headers,
              })

              .then(function(response) {})
              .catch(function(e) {
                //" Erro ao tentar criar condição para dispositivo: ",e);
              });
          }
        } else {
          let devices_list = selected_devices_formatted.split(",", 100);

          for (let i = 0; i < devices_list.length; i++) {
            data = {
              deviceId: devices_list[i],
              condicoId: condition,
              groupId: store.state.active_group.id,
            };

            const headers = {
              Authorization: this.$store.state.token_api,
            };

            await axios
              .post("https://tekie.cloud:3031/condicoes-devices", data, {
                headers,
              })

              .then(function(response) {})
          }
        }
        this.initial_requests();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.condition_card {
  z-index: 0;
}
.condition_card:hover {
  scale: 1.03;
  z-index: 1;
}
[dir] .dropdown-toggle::after {
  background-image: none !important;
}
[dir] .b-nav-dropdown .dropdown-toggle::after {
  background-image: none !important;
}
</style>
