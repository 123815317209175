<template>
  <b-row style="height: 100%">
    <b-col md="3">
      <h6 class="filter-heading d-none d-lg-block">Dispositivos:</h6>
      <b-card class="mb-4">
        <label v-for="(device, index) in devices" :key="'device' + index">
          <b-form-radio
            @change="onChangeDevice(selected_devices)"
            v-model="selected_devices"
            :value="device.id"
            class="custom-control-success"
          >
            <b-row>
              <b-col md="12">
                {{ device.name }}
              </b-col>
            </b-row>
          </b-form-radio>
        </label>
      </b-card>
    </b-col>
    <b-col md="9" style="height: 100%; margin-bottom: 2rem">
      <b-card class="mb-8" style="height: 100%; width: 100%">
        <div id="map-container" style="width: 100%; height: 100%">
          <MglMap
            :key="cont"
            container="map-container"
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="center"
            :zoom="zoom"
            @load="onMapLoaded"
          >
            <!-- MglMarker para dashboard -->
            <MglMarker
              v-for="(item, index) in coordinatesMarker"
              :key="'MakerId_' + index + cont"
              :coordinates="coordinatesMarker[index]"
              color="#00D455"
            >
              <MglPopup>
                <div class="demo-inline-spacing">
                  <b-avatar
                    variant="light-dark"
                    style="margin-right: 0.5rem; margin-top: 0rem"
                  >
                    <feather-icon
                      :icon="possible_icons[devices[index].icon].icon"
                      size="20"
                    />
                  </b-avatar>
                  <div style="margin-top: 0rem">
                    <h5 class="mb-0">
                      {{ devices[index].name }}
                    </h5>
                    <small class="text-muted">
                      {{ new Date(item[2] * 1000).toLocaleString() }}
                    </small>
                  </div>
                </div>
              </MglPopup>
            </MglMarker>
            <MglNavigationControl position="top-right" />
          </MglMap>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormRadio,
  BButton,
  BFormSpinbutton,
} from "bootstrap-vue";
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglNavigationControl, MglPopup } from "v-mapbox";
import axios from "axios";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import EventBus from "@/store/eventBus";
import api from "../../service/api.js";
import store from "@/store/index";

export default {
  props: ["request", "request_fence"],
  components: {
    BFormSpinbutton,
    BFormCheckbox,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormRadio,
    MglMap,
    MglMarker,
    BButton,
    MglNavigationControl,
    MglPopup,
    MapboxDraw,
  },

  data() {
    return {
      cont: 0,
      zoom: 4,
      coordinates: [],
      draw: "",
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
      all_latitude: [],
      all_longitude: [],
      accessToken:
        "pk.eyJ1IjoiZHRpYWdvIiwiYSI6ImNrYzB4b2JlODE4azMyc3U2dnJ0M3RzdmIifQ.JjLKI9mfpEot4J2FNEtNOQ",
      mapStyle: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [-46.9397181, -19.5933794],
      coordinatesMarker: [],
      coordinatesToGo: [0, 0],
      devices: [],
      selected_devices: [],
    };
  },
  async created() {
    var self = this;
    this.mapbox = Mapbox;

    EventBus.$on("getFenceData", () => {
      this.updateArea();
    });

    let response = await api.get_devices()
    this.devices = response.filter(e => e.groupId == store.state.active_group.id);

    for (let i = 1; i <= this.devices.length; i++) {
      let response = await api.get_lasts_coords(
        this.request.split("&id=")[i]
      );
      self.coordinatesMarker[i - 1] = [
        response[0].value.split(",")[1],
        response[0].value.split(",")[0],
        response[0].valueTime,
      ];
    }
  },
  methods: {
    async onMapLoaded(event) {
      this.map = event.map;
      this.$store.map = event.map;
      this.map.resize();
      this.asyncActions = event.component.actions;
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: "draw_polygon",
      });

      this.map.addControl(this.draw);
      this.map.on("draw.create", this.updateArea);
      if (this.request_fence != "" && this.request_fence != undefined) {
        let cutted_fence = this.request_fence.split("],[", 30);
        var feature = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              id: "example-id",
              geometry: { type: "Polygon", coordinates: [] },
            },
          ],
        };
        feature.features[0].geometry.coordinates[0] = [];
        cutted_fence.forEach((element, index) => {
          feature.features[0].geometry.coordinates[0][index] = [
            Number(element.split(",", 2)[0]),
            Number(element.split(",", 2)[1]),
          ];
        this.draw.set(feature);
        });
        this.map.jumpTo({ center: feature.features[0].geometry.coordinates[0][0], zoom: 16 });
      }
    },

    updateArea() {
      var map_lines = '[';
      for (        
        let i = 0;
        i < this.draw.getAll().features[0].geometry.coordinates[0].length;
        i++
      ) {
        map_lines +=
          "[" +
          this.draw.getAll().features[0].geometry.coordinates[0][i][0] +
          "," +
          this.draw.getAll().features[0].geometry.coordinates[0][i][1];
        if (
          i !=
          this.draw.getAll().features[0].geometry.coordinates[0].length - 1
        ) {
          map_lines += "],";
        }
      }
      map_lines += "]]";
      EventBus.$emit("fencesData", map_lines);
    },

    async onChangeDevice(device) {
      let response = await api.get_lasts_coords(device);

      this.coordinatesToGo = [response[0].value.split(',')[1], response[0].value.split(',')[0]];
      this.newParams = await this.asyncActions.flyTo({
        center: this.coordinatesToGo,
        zoom: 17,
        speed: 1,
      });
    },
  },
};
</script>

<style></style>
